import { render, staticRenderFns } from "./DesignerLayout.vue?vue&type=template&id=51be63f4&"
import script from "./DesignerLayout.vue?vue&type=script&lang=js&"
export * from "./DesignerLayout.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports